import request from '@/utils/axios'

/** 获取徽章列表 */
export function getList(params) {
  return request({
    url: '/badge',
    method: 'get',
    params
  })
}

/** 获取徽章详情 */
export function getDetail(id) {
  return request({
    url: `/badge/detail?id=${id}`,
    method: 'get'
  })
}

/** 添加徽章 */
export function add(data) {
  return request({
    url: '/badge',
    method: 'post',
    data
  })
}

/** 编辑徽章 */
export function edit(data) {
  return request({
    url: '/badge',
    method: 'put',
    data
  })
}

/** 删除徽章 */
export function del(id) {
  return request({
    url: `/badge?id=${id}`,
    method: 'delete'
  })
}

