<template>
  <el-dialog
    v-if="show"
    :title="`${id ? '编辑' : '新增'}徽章`"
    :visible.sync="show"
  >
    <el-form v-if="show" :model="form" label-width="auto">
      <el-form-item label="徽章名称">
        <el-input v-model="form.name" />
      </el-form-item>
      <el-form-item label="徽章图片">
        <el-upload
          action="#"
          :http-request="uploadImage"
          :show-file-list="false"
          :limit="1"
        >
          <el-image v-if="form.imgUrl" :src="form.imgUrl" />
          <i v-else class="el-icon-plus uploader-icon" />
          <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
        </el-upload>
      </el-form-item>
      <el-form-item label="徽章数量">
        <el-input v-model="form.total" />
      </el-form-item>
      <el-form-item label="徽章说明">
        <el-input v-model="form.content" type="textarea" />
      </el-form-item>
    </el-form>
    <el-row type="flex" justify="end">
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
import form from '@/mixins/form'
import * as api from '@/api/badge'

export default {
  mixins: [form],
  data() {
    return {
      api,
      show: false,
      form: {
        imgUrl: ''
      }
    }
  },
  created() {
    console.log(this.form)
  },
  methods: {
    uploadImage({ file }) {
      this.upload(file).then(res => {
        this.form.imgUrl = res.data.fileUrl
        // this.$forceUpdate()
      })
    }
  }
}
</script>
