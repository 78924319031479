<template>
  <div>
    <div class="filter-container">
      <el-form inline style="display: flex;justify-content: flex-end;">
        <el-form-item>
          <el-button size="medium" type="primary" icon="el-icon-plus" @click="add">新增</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="list">
      <el-table-column
        prop="id"
        label="ID"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="name"
        label="徽章名字"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="imgUrl"
        label="徽章图片"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="{ row }">
          <el-image :src="row.imgUrl" style="width: 100px;height: 50px" :preview-src-list="[row.imgUrl]" />
        </template>
      </el-table-column>
      <el-table-column
        prop="content"
        label="徽章说明"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="total"
        label="徽章总数量"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="surplus"
        label="徽章剩余数量"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="createTime"
        label="创建时间"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        label="操作"
        align="center"
      >
        <template slot-scope="{ row }">
          <el-row>
            <el-button size="small" type="primary" @click="edit(row.id)">编辑</el-button>
            <el-button size="small" type="danger" @click="del(row.id)">删除</el-button>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <div class="page-container">
      <el-pagination
        background
        :total="count"
        :page-sizes="pageSizes"
        :page-size.sync="pageSize"
        :current-page.sync="page"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="refresh"
        @current-change="(val) => {
          page = val
          getList()
        }"
      />
    </div>
    <Form ref="form" />
  </div>
</template>

<script>
import list from '@/mixins/list'
import * as api from '@/api/badge'
import Form from './form'
export default {
  components: { Form },
  mixins: [list],
  data() {
    return {
      api
    }
  }
}
</script>
